





















import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import LargeProduct from '@/models/graphql/LargeProduct';
import EntityType from '@/utils/enums/EntityType';
import Product from '@/models/graphql/Product';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';

@Component({
  inheritAttrs: false,
})
export default class VideoEmbedWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'VideoEmbedWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private readonly title!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly useUrl!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly url!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly entityType!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly data!: Data;

  private showVideo = false;

  private videoUrl = '';

  get videoSrc(): string {
    if (this.useUrl === 'true' && this.url) {
      return this.getYoutubeUrl(this.url);
    }
    if (this.useUrl === 'false' && this.data) {
      if (
        this.entityType.toLowerCase() === (EntityType.LARGE_PRODUCT as string).toLowerCase()
          && (this.data as unknown as LargeProduct).videoUrl
      ) {
        return this.getYoutubeUrl((this.data as unknown as LargeProduct).videoUrl as string);
      }
      if (
        this.entityType.toLowerCase() === (EntityType.PRODUCT as string).toLowerCase()
          && (this.data as unknown as Product).videoUrl
      ) {
        return this.getYoutubeUrl((this.data as unknown as Product).videoUrl as string);
      }
      return '';
    }
    return '';
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    this.setVideoUrl();
  }

  @Watch('data')
  @Watch('useUrl')
  @Watch('url')
  private setVideoUrl(): void {
    this.videoUrl = this.videoSrc;
    if (this.videoUrl) {
      this.showVideo = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getYoutubeUrl(url: string): string {
    return url.replace('/watch?v=', '/embed/');
  }
}
